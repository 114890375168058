$primary: #173b68;
$danger: #b11926;
$primary-dark: #1c3255;
$theme-colors: (
  'primary': $primary,
  'danger': $danger,
);

$font-face: 'BMWGroup Regular';
$font-condensed: 'BMWGroup Condensed';
$font-family-base: $font-condensed, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji' !default;
$font-primary: $font-family-base;
$font-mono: $font-primary;

$font-size-base: 1.3rem !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-xs: $font-size-sm !default;

$custom-control-indicator-size: 1.6rem;

$spacer: 1rem !default;

$body-bg: white;
$btn-disabled-opacity: 0.65;

$font-family-sans-serif: $font-family-base;

$theme-colors: (
  'primary': $primary
);

$container-max-widths: (
  xl: 1560px
) !default;

// #BREAKPOINTS
//
// Breakpoints used in media queries
// 1) These are not the only breakpoints used, but they provide a few defaults
// Use Boostrap 5 breakpoints
// https://getbootstrap.com/docs/5.0/layout/breakpoints/
$bp-xs: 0;
$bp-sm: 576px;
$bp-med: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1400px;

// for mapping bootstrap breakpoints
$grid-breakpoints: (
  xs: $bp-xs,
  sm: $bp-sm,
  md: $bp-med,
  lg: $bp-lg,
  xl: $bp-xl,
  xxl: $bp-xxl,
);
