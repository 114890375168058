.check-list {
  .check-list__item {
    display: flex;
    justify-content: space-between;
    &:last-child {
      padding-bottom: 10px;
      border-bottom: 1px solid get-color(("smoke", "mui-smoke"), $gray-400);
    }
    label,
    .filter-count {
      font-size: 1.4rem;
      @media (max-width: 480px) {
        font-size: 1.6rem;
      }
    }
  }
  .checklist-list__items {
    display: flex;
    justify-content: space-between;
    &:last-child {
      padding-bottom: 10px;
      border-bottom: 1px solid get-color(("smoke", "mui-smoke"), $gray-400);
    }
    label,
    .filter-count {
      font-size: 1.4rem;
      @media (max-width: 480px) {
        font-size: 1.6rem;
      }
    }
  }

  .check-list__heading {
    color: get-color(("slate", "mui-slate"), $gray-700);
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
    &-icon {
      padding-right: 0.4rem;
    }
  }
}
