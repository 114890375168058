/*------------------------------------*\
    #FORMS
\*------------------------------------*/

/**
 * 1) Form element base styles
 */

/**
 * Input placeholder text base styles
 */

 ::-webkit-input-placeholder {
  color: get-color(("slate","mui-slate"), $gray-600);
}

::-moz-placeholder {
  color: get-color(("slate","mui-slate"), $gray-600);
}

:-ms-input-placeholder {
  color: get-color(("slate","mui-slate"), $gray-600);
}

/**
 * Fieldset base styles
 */

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

/**
 * Legend base styles
 */

legend {
  margin-bottom: 0.25rem;
}

/**
 * Label base styles
 */

label {
  display: block;
  color: get-color(("charcoal","mui-charcoal"), $gray-800);
  font-size: 1.6rem;
}

/**
 * Add font size 100% of form element and margin 0 to these elements
 */

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: $font-size-md;
  margin: 0;
}

/**
 * Text area base styles
 */

textarea {
  min-height: 6rem;
  resize: none;
}

/**
 * Input  and text area base styles
 */

input,
textarea {
  width: 100%;
  border: 1px solid get-color(("smoke","mui-black"), $gray-400);
  background-color: get-color(("white","mui-white"), $white);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.form-control {
  border: 1px solid get-color(("smoke","mui-black"), $gray-400);
  height: 40px;
  background-color: get-color(("white","mui-white"), $white);
  color: get-color(("black","mui-black"), $black);
  border-radius: 2px;
  font-size: 1.6rem;
  padding-left: 0.8rem;
  &input[type='text'],
  textarea {
    color: black;
    width: 100%;
    padding: 0.8rem;
    height: 40px;
    border: 1px solid get-color(("smoke","mui-black"), $gray-400);
    &:focus {
      border-color: get-color(("smoke","mui-blue"), $gray-400);
    }
  }
  &:focus,
  &.focus {
    outline: 0;
    border-color: get-color(("smoke","mui-blue"), $gray-400);
    box-shadow: 0 0 4px 2px get-color(("smoke","mui-smoke"), $gray-400);
    color: get-color(("black","mui-black"), $black);
  }
  &:disabled,
  .disabled {
    background-color: get-color(("ash","mui-ash"), $gray-200);
    color: rgba(0, 0, 0, 0.5);
    cursor: no-drop;
  }
  &-sm {
    padding-left: 0.8rem;
    font-size: 1.4rem;
    background-color: get-color(("white","mui-white"), $white);
    color: get-color(("black","mui-black"), $black);
    height: 3.2rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    -webkit-border-radius: 0.2rem;
    -moz-border-radius: 0.2rem;
    -ms-border-radius: 0.2rem;
    -o-border-radius: 0.2rem;
    &:focus,
    &.focus {
      outline: 0;
      border-color: get-color(("smoke","mui-blue"), $gray-400);
      box-shadow: 0 0 4px 2px get-color(("smoke","mui-smoke"), $gray-400);
      color: get-color(("black","mui-black"), $black);
    }
    &:disabled,
    .disabled {
      background-color: get-color(("ash","mui-ash"), $gray-200);
      color: rgba(0, 0, 0, 0.5);

      cursor: no-drop;
    }
  }
  &-has-append__right {
    padding-right: 1.6rem;
  }
}

.form-group {
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
  .text-input__has-icon {
    &-left {
      margin-left: -24px;
      padding-left: 28px;
    }
    &-right {
      margin-right: -28px;
      padding-right: 28px;
    }
  }
  .text-input__icon {
    &-left {
      z-index: 2200;
      padding-left: 8px;
    }
  }
  &.form-group-sm {
    label {
      font-size: 1.4rem;
    }
  }
  &.form-group__has-feedback {
    margin-bottom: 1.6rem;
  }
  &-inline {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1.6rem;
    label,
    label.col-form-label,
    label.col-form-label-sm {
      padding-top: 0;
      margin-bottom: 0;
      padding-right: 8px;
    }
  }
}

.input-group-append {
  .input-group-text {
    padding: 0;
  }
  i {
    width: 4rem;
  }
}
.input-group {
  .input-group-prepend {
    margin-right: -1px;
    .btn, .btn-sm, .btn-lg {
      border-bottom-right-radius: 0rem;
      border-top-right-radius: 0rem;
    }
  }
  .input-group-append {
    margin-left: -1px;
    .btn, .btn-sm, .btn-lg {
      border-bottom-left-radius: 0rem;
      border-top-left-radius: 0rem;
    }
  }
}

.form-feedback__icon {
  font-size: 14px;
  margin-right: 4px;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: get-color(("jungle","mui-green"), $green);

  &:not([type='password']) {
    background-image: url("data:image/svg+xml,%3C!-- Generated by IcoMoon.io --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Ctitle%3Echeckmark-circle%3C/title%3E%3Cpath fill='%230d8240' d='M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM6.5 12.031l-2.813-3.344 1.031-1.031 1.781 1.75 5.156-4.5 0.719 0.719-5.875 6.406z'%3E%3C/path%3E%3C/svg%3E%0A");
  }
  &[type='password'] {
    background-image: none;
  }
  &.text-input__has-icon-right {
    background-image: none;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.prism-select {
  .prism-select__toggle.form-control.is-invalid {
    background-image: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDAuNUw2IDYuNUwxMiAwLjVIMFoiIGZpbGw9IiNDMzNBMDAiLz4KPC9zdmc+Cg==');
  }
}

.valid-feedback {
  color: get-color(("jungle","mui-green"), $green);
  border-color: get-color(("charcoal","mui-charcoal"), $gray-800);
  display: none !important;
}

.invalid-feedback {
  color: get-color(("scarlet","mui-red"), $red);
  border-color: get-color(("charcoal","mui-charcoal"), $gray-800);
  margin-bottom: -0.8rem;
  margin-top: 0.8rem;
  font-size: 14px;
  font-family: $font-primary;
}

.password-toggle__icon {
  cursor: pointer;
  font-size: 1.6rem;
  margin-top: 0.3rem;
}

.input-group-prepend {
  margin-right: 0;
}

.input-search__btn {
  cursor: pointer;
}
/**
 * Remove webkit appearance styles from these elements
 */

input[type='text'],
input[type='search'],
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='url'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}

/**
 * Checkbox and radio button base styles
 */

input[type='checkbox'],
input[type='radio'] {
  width: auto;
  margin-right: 0.3rem;
  border-color: get-color(("charcoal","mui-charcoal"), $gray-800);
}

// input[type='checkbox'] {
//   &:checked {
//     color: theme-color("primary");
//     background-color: theme-color("primary");
//   }
// }

/**
 * Search input base styles
 */

input[type='search'] {
  -webkit-appearance: none;
  border-radius: 0;
}
// /fonts/PrismSVG/SVG/chevron-down.svg
/**  custom checkboxes **/

label {
  display: inline-block;
  color: get-color(("charcoal","mui-charcoal"), $gray-800);
  padding-bottom: 0;
  &.col-form-label {
    padding-left: 0 !important;
    padding: 0;
    margin-bottom: 8px;
    font-size: 1.6rem;
    line-height: 1;
    &-sm {
      padding-bottom: 0;
      padding-left: 0 !important;
      margin-bottom: 8px;
      font-size: 1.4rem;
      line-height: 1;
    }
  }
}

.number-input__group {
  width: 20rem;
  height: 40px;
  align-items: center;
  justify-content: center;
  input.form-control {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 46px;
  }
  button {
    width: 4rem;
  }
  .input-group-append {
    margin: 0;
    width: 4rem;
    padding: 0;
    button.btn {
      margin: 0;
    }
  }
  .input-group-prepend {
    margin: 0;
    width: 4rem;
    padding: 0;
    button.btn {
      margin: 0;
    }
  }
}

.prism-check__label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover .prism-check__checkmark {
    background-color: white;
    border: 1px solid theme-color("primary");
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .prism-check__checkmark {
      display: block;
      background-color: theme-color("primary");
      color: white;
      fill: white;
    }
    &:checked ~ .prism-check__checkmark:after {
      display: block;
    }
    .prism-check__checkmark {
      color: white;
      &:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .prism-check__checkmark {
    color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 2px;
    &:before {
      fill: theme-color("primary");
      stroke: theme-color("primary");
      color: white;
      content: '\EE73';
    }
  }
}

/* END MUI Overrides */

$opacity--disabled: 0.4;

.text-input {
  &__has-icon {
    &-left {
      margin-left: -23px;
      padding-left: 28px;
    }

    &-right {
      margin-right: -24px;
      padding-right: 16px;
    }
  }
  &__icon {
    &-left {
      z-index: 102;
      padding-left: 8px;
      display: inline-flex;
      align-items: center;
    }
    &-right {
      z-index: 104;
      display: inline-flex;
      align-items: center;
    }
  }
}
.input-group-append {
  span {
    margin-left: 22px;
    padding-right: 8px;
  }
}
.input-group-prepend {
  margin-right: 9px;
  vertical-align: middle;
  &:before {
    vertical-align: middle;
  }
}

.interactive-input__label {
  &.icon-before {
    margin-left: 24px;
  }
}
